<template>
  <header>
    <div class="navbar navbar-dark bg-dark shadow-sm my-0">
      <div class="container-fluid">
        <router-link to="/" href="#" class="navbar-brand d-flex align-items-center py-0">
          <img :src="projectLogo" class="img-fluid" alt="Responsive image" />
          <strong>{{ projectName }}</strong>
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import projectPreferences from '@/assets/data/project.json'
export default {
  name: 'TheHeader',
  data() {
    return {}
  },

  computed: {
    projectName() {
      return projectPreferences.projectName
    },
    projectLogo() {
      return projectPreferences.projectLogo
    }
  },
  created() {
    document.title = projectPreferences.projectName
    document.getElementById('favicon').href = projectPreferences.projectLogo
  }
}
</script>

<style scoped>
.navbar img {
  height: 30px;
  margin-right: 20px;
}

.navbar strong {
  font-size: 1.5em;
  letter-spacing: calc(11px + 1vw);
  line-height: 1px;
}
</style>
